'use strict'
Object.defineProperty(exports, '__esModule', { value: true })
exports.useInactiveListener = exports.useEagerConnect = void 0
const core_1 = require('@telekomconsalting/core')
const react_1 = require('react')
const eagerConnect_1 = require('./eagerConnect')
function useEagerConnect(suppress = false, walletConnectors) {
  const { activate, active, connector } = (0, core_1.useWeb3React)()
  const [tried, setTried] = (0, react_1.useState)(false)
  ;(0, react_1.useEffect)(() => {
    if (suppress) {
      return
    }
    connect(walletConnectors)
  }, [])
  ;(0, react_1.useEffect)(() => {
    if (suppress) {
      return
    }
    if (!active && !connector) {
      connect(walletConnectors)
    }
  }, [walletConnectors])
  const connect = (walletConnectors) => {
    const injected =
      walletConnectors === null || walletConnectors === void 0
        ? void 0
        : walletConnectors.getInjectedConnector()
    injected === null || injected === void 0
      ? void 0
      : injected.isAuthorized().then((isAuthorized) => {
          if (isAuthorized) {
            activate(injected, undefined, true).catch(() => {
              setTried(true)
            })
          } else {
            setTried(true)
          }
        })
  }
  ;(0, react_1.useEffect)(() => {
    if (suppress) {
      return
    }
    if (!tried && active) {
      setTried(true)
    }
  }, [tried, active])
  return tried
}
exports.useEagerConnect = useEagerConnect
function useInactiveListener(suppress = false, walletConnectors) {
  const { active, error, activate } = (0, core_1.useWeb3React)()
  ;(0, react_1.useEffect)(() => {
    const { ethereum } = window
    if (
      (ethereum === null || ethereum === void 0 ? void 0 : ethereum.on) &&
      !active &&
      !error &&
      !(suppress || (0, eagerConnect_1.isSuppressEagerConnect)())
    ) {
      const injected =
        walletConnectors === null || walletConnectors === void 0
          ? void 0
          : walletConnectors.getInjectedConnector()
      if (!injected) {
        return
      }
      const handleConnect = () => {
        console.log("Handling 'connect' event")
        activate(injected)
      }
      const handleChainChanged = (chainId) => {
        console.log('chainChanged', chainId)
        activate(injected)
      }
      const handleAccountsChanged = (accounts) => {
        console.log('accountsChanged', accounts)
        if (accounts.length > 0) {
          activate(injected)
        }
      }
      const handleNetworkChanged = (networkId) => {
        console.log('networkChanged', networkId)
        activate(injected)
      }
      ethereum.on('connect', handleConnect)
      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)
      ethereum.on('networkChanged', handleNetworkChanged)
      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('connect', handleConnect)
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
          ethereum.removeListener('networkChanged', handleNetworkChanged)
        }
      }
    }
    return () => {}
  }, [active, error, suppress, activate])
}
exports.useInactiveListener = useInactiveListener
